import * as React from "react";
import { Dialog, Box, Typography, IconButton, CircularProgress, Skeleton } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";
import { NavigationButton } from "./components/NavigationButton";
import { PageThumbnail } from "./pageThumbnail";
import CloseIcon from "@mui/icons-material/Close";
import { Document, Page } from "react-pdf";

interface FullScreenPDFDialogProps {
    open: boolean;
    onClose: () => void;
    pdfName: string;
    pdfUrl: string;
    pageNumbers: number[];
    initialPage?: number;
}

export const FullScreenPDFDialog: React.FC<FullScreenPDFDialogProps> = React.memo(
    ({ open, onClose, pdfName, pdfUrl, pageNumbers, initialPage = 1 }: FullScreenPDFDialogProps) => {
        const { width, ref } = useResizeDetector({
            refreshMode: "debounce",
            refreshRate: 100,
        });

        const [pageNumber, setPageNumber] = React.useState(initialPage);
        const [numPages, setNumPages] = React.useState<number | null>(null);

        // const handlePageChange = React.useCallback((newPage: number) => {
        //     setPageNumber(newPage);
        // }, []);

        const goToPrevPage = React.useCallback(() => {
            setPageNumber(prev => Math.max(prev - 1, 1));
        }, []);

        const goToNextPage = React.useCallback(() => {
            setPageNumber(prev => Math.min(prev + 1, numPages ?? prev));
        }, [numPages]);

        const onDocumentLoadSuccess = React.useCallback(({ numPages }: { numPages: number }) => {
            setNumPages(numPages);
        }, []);

        const handlePageSelect = React.useCallback((pageNum: number) => {
            setPageNumber(pageNum);
        }, []);

        const [pageInputValue, setPageInputValue] = React.useState<string | undefined>(undefined);

        const handlePageInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setPageInputValue(value);
        }, []);

        const handlePageInputBlur = React.useCallback(() => {
            const newPage = parseInt(pageInputValue ?? pageNumber.toString(), 10);
            if (!isNaN(newPage) && newPage >= 1 && newPage <= (numPages ?? 1)) {
                setPageNumber(newPage);
            }
            setPageInputValue(undefined);
        }, [pageInputValue, numPages, pageNumber]);

        const handleKeyPress = React.useCallback(
            (event: React.KeyboardEvent) => {
                if (event.key === "Enter") {
                    handlePageInputBlur();
                }
            },
            [handlePageInputBlur],
        );

        React.useEffect(() => {
            setPageInputValue(pageNumber.toString());
        }, [pageNumber]);

        if (!open) {
            return null;
        }

        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
                sx={{
                    "& .MuiDialog-paper": {
                        bgcolor: "surface.50",
                    },
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        px: 1,
                        py: 2,
                    }}
                >
                    {/* Header */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <IconButton onClick={onClose}>
                            <CloseIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                        <Typography variant="body1" sx={{ color: "secondary.main", fontWeight: 550 }}>
                            {pdfName}
                        </Typography>
                    </Box>

                    {/* Main Content */}
                    <Box
                        sx={{
                            flex: 1,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                        }}
                        ref={ref}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                overflow: "auto",
                                bgcolor: "grey.100",
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                position: "relative",
                            }}
                        >
                            <Document
                                className="highlight-block"
                                file={pdfUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={<CircularProgress />}
                                error={<Typography sx={{ p: 2 }}>Failed to load PDF</Typography>}
                            >
                                <Page
                                    className="highlight-block"
                                    pageNumber={pageNumber}
                                    width={width ? width - 48 : undefined}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    loading={<Skeleton variant="rectangular" width="100%" height={800} />}
                                />
                            </Document>

                            {/* Updated Navigation Controls */}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 16,
                                    left: 32,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                <Typography variant="body2" sx={{ color: "neutrals.60" }}>
                                    Page
                                </Typography>
                                <input
                                    value={pageInputValue}
                                    onChange={handlePageInputChange}
                                    onBlur={handlePageInputBlur}
                                    onKeyPress={handleKeyPress}
                                    style={{
                                        width: "40px",
                                        padding: "4px 8px",
                                        border: "1px solid #E0E0E0",
                                        borderRadius: "4px",
                                    }}
                                />
                                <Typography variant="body2" sx={{ color: "neutrals.60" }}>
                                    of {numPages ?? "?"}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 16,
                                    right: 16,
                                    display: "flex",
                                    gap: 1,
                                }}
                            >
                                <NavigationButton direction="left" onClick={goToPrevPage} disabled={pageNumber <= 1} />
                                <NavigationButton
                                    direction="right"
                                    onClick={goToNextPage}
                                    disabled={pageNumber >= (numPages ?? 1)}
                                />
                            </Box>
                        </Box>

                        {/* Relevant Pages Section */}
                        <Box sx={{ ml: 2, mt: 2, overflowX: "auto", pb: 2 }}>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    color: "secondary.main",
                                    mb: 1,
                                    fontWeight: 500,
                                }}
                            >
                                Relevant Pages
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                }}
                            >
                                {pageNumbers.map(pageNum => (
                                    <PageThumbnail
                                        key={pageNum}
                                        pageNumber={pageNum}
                                        pdfUrl={pdfUrl}
                                        isSelected={pageNumber === pageNum}
                                        onClick={() => handlePageSelect(pageNum)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        );
    },
);

FullScreenPDFDialog.displayName = "FullScreenPDFDialog";
