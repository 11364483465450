import React from "react";
import { Box, Typography, Avatar, Button, IconButton, useTheme } from "@mui/material";
import { Call, Sms } from "iconsax-react";
import TeamsIconSrc from "../assets/teams-icon.svg";
import { Sources } from "./types";
import { CitedSourceBox, CitedSourcePage } from "../shared/citedSourceBox";

interface PersonData {
    name: string;
    grade: string;
    image: string | null;
}

interface PersonAnswerSectionProps {
    person: PersonData;
    aiAnswer: string | undefined;
    citedSources: Sources | undefined;
    onSelectSource: (sourceIdx: number, page: number | undefined) => void;
}

type SectionId = "overview" | "projects" | "org-chart";

const SECTION_INFO_BY_ID: Record<SectionId, string> = {
    overview: "Overview",
    projects: "Projects",
    "org-chart": "Org Chart",
};

export const PersonAnswerSection: React.FC<PersonAnswerSectionProps> = ({
    person,
    citedSources,
    aiAnswer,
    onSelectSource,
}) => {
    const [selectedSection, setSelectedSection] = React.useState<SectionId>("overview");

    const theme = useTheme();

    return (
        <>
            <Box sx={{ display: "flex", overflowX: "auto", mb: 2 }}>
                {citedSources?.map((source, index) => (
                    <PersonAnswerSectionCitedSourceBox
                        key={index}
                        fileName={source.file_name}
                        pages={source.pages}
                        sourceIdx={index}
                        onSelect={onSelectSource}
                    />
                ))}
            </Box>
            <Box
                sx={{
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 1,
                    p: 2,
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    rowGap: 2,
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", overflowX: "hidden", position: "relative" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            columnGap: 2,
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: 2 }}>
                            <Avatar
                                src={person.image || undefined}
                                alt={person.name}
                                sx={{
                                    width: 84,
                                    height: 84,
                                    mb: 2,
                                    borderRadius: 1,
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "stretch",
                                    height: 84,
                                    gap: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: 0.5,
                                    }}
                                >
                                    <Typography variant="body1" sx={{ color: "secondary.main", fontWeight: 500 }}>
                                        {person.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "neutrals.60" }}>
                                        {person.grade}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "stretch", flexGrow: 1, gap: 1 }}>
                                    <Box sx={{ display: "flex", gap: 1 }}>
                                        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                                        {Object.entries(SECTION_INFO_BY_ID).map(([id, _]) => (
                                            <SectionButton
                                                key={id}
                                                id={id as SectionId}
                                                isSelected={selectedSection === id}
                                                onSelect={setSelectedSection}
                                            />
                                        ))}
                                    </Box>
                                    <Typography
                                        variant="caption"
                                        noWrap
                                        sx={{
                                            color: "neutrals.60",
                                            justifySelf: "end",
                                            position: "absolute",
                                            top: 60,
                                            right: 0,
                                        }}
                                    >
                                        Leveraged OpenAir
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <IconButton
                                size="small"
                                sx={{
                                    border: 1,
                                    p: 1,
                                    borderColor: "neutrals.30",
                                    "&:hover": { bgcolor: "surface.60" },
                                }}
                            >
                                <img src={TeamsIconSrc} alt="Teams" style={{ width: 16, height: 16 }} />
                            </IconButton>
                            <IconButton
                                size="small"
                                sx={{
                                    border: 1,
                                    p: 1,
                                    borderColor: "neutrals.30",
                                    "&:hover": { bgcolor: "surface.60" },
                                }}
                            >
                                <Call size={16} color={theme.palette.neutrals[70]} />
                            </IconButton>
                            <IconButton
                                size="small"
                                sx={{
                                    border: 1,
                                    p: 1,
                                    borderColor: "neutrals.30",
                                    "&:hover": { bgcolor: "surface.60" },
                                }}
                            >
                                <Sms size={16} color={theme.palette.neutrals[70]} />
                            </IconButton>
                        </Box>
                    </Box>
                    {aiAnswer != null && <Typography variant="body2">{aiAnswer}</Typography>}
                    {/* <Button
                variant="contained"
                color="secondary"
                startIcon={<Message size={20} />}
                onClick={onFollowUp}
                disabled={isFollowUpPending}
                sx={{ borderRadius: 12 }}
            >
                {isFollowUpPending ? "Creating chat…" : "Follow up"}
            </Button> */}
                </Box>
            </Box>
        </>
    );
};

interface PersonAnswerSectionCitedSourceBoxProps {
    fileName: string;
    sourceIdx: number;
    pages: CitedSourcePage[];
    onSelect: (sourceIdx: number, page: number | undefined) => void;
}

const PersonAnswerSectionCitedSourceBox: React.FC<PersonAnswerSectionCitedSourceBoxProps> = ({
    fileName,
    sourceIdx,
    pages,
    onSelect,
}) => {
    const handleSelect = React.useCallback(
        (page: number | undefined) => onSelect(sourceIdx, page),
        [sourceIdx, onSelect],
    );
    return <CitedSourceBox fileName={fileName} pages={pages} onSelect={handleSelect} />;
};

interface SectionButtonProps {
    id: SectionId;
    isSelected: boolean;
    onSelect: (id: SectionId) => void;
}

const SectionButton: React.FC<SectionButtonProps> = ({ id, isSelected, onSelect }) => {
    const handleSelect = React.useCallback(() => onSelect(id), [id, onSelect]);
    return (
        <Button
            onClick={handleSelect}
            size="small"
            sx={{
                color: isSelected ? "white" : "neutrals.70",
                bgcolor: isSelected ? "neutrals.60" : "surface.75",
                borderRadius: 12,
                px: 2,
            }}
        >
            <Typography variant="body2" noWrap>
                {SECTION_INFO_BY_ID[id]}
            </Typography>
        </Button>
    );
};
