import { queryOptions } from "@tanstack/react-query";
import { coreChatRouterGetChat, coreChatRouterListChats } from "../backend-client/generated/services.gen";
import { coreAuthRouterGetCurrentUser, coreAuthRouterGetTenant } from "../backend-client/generated/services.gen";

export const chatQueryOptions = (chatId: string) =>
    queryOptions({
        queryKey: ["chat", chatId],
        queryFn: () => coreChatRouterGetChat({ path: { chat_id: chatId } }).then(response => response.data),
    });

export const chatsQueryOptions = queryOptions({
    queryKey: ["chats"],
    queryFn: () => coreChatRouterListChats().then(response => response.data),
});

const CURRENT_USER_CACHE_KEY = "cached_current_user";
export const currentUserQueryOptions = queryOptions({
    queryKey: ["currentUser"],
    queryFn: () =>
        coreAuthRouterGetCurrentUser({
            throwOnError: true,
            credentials: "include",
        }).then(response => {
            // Save to localStorage when we get new data
            localStorage.setItem(CURRENT_USER_CACHE_KEY, JSON.stringify(response.data));
            return response.data;
        }),
    placeholderData: () => {
        // Load from localStorage if available
        const cached = localStorage.getItem(CURRENT_USER_CACHE_KEY);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cached ? JSON.parse(cached) : undefined;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
});

const TENANT_CACHE_KEY = "cached_tenant";
export const tenantQueryOptions = queryOptions({
    queryKey: ["tenant"],
    queryFn: () =>
        coreAuthRouterGetTenant({ throwOnError: true }).then(response => {
            // Save to localStorage when we get new data
            localStorage.setItem(TENANT_CACHE_KEY, JSON.stringify(response.data));
            return response.data;
        }),
    placeholderData: () => {
        // Load from localStorage if available
        const cached = localStorage.getItem(TENANT_CACHE_KEY);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cached ? JSON.parse(cached) : undefined;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
});
