import * as React from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { Filter } from "./filter";
import { Folder } from "iconsax-react";

interface ProjectFilterProps {
    selectedProjects: string[];
    onProjectsChange: (projects: string[]) => void;
}

const projects = ["Artemis", "Parade"];

export const ProjectFilter: React.FC<ProjectFilterProps> = ({ selectedProjects, onProjectsChange }) => {
    const handleProjectChange = React.useCallback(
        (project: string) => {
            onProjectsChange(
                selectedProjects.includes(project)
                    ? selectedProjects.filter(p => p !== project)
                    : [...selectedProjects, project],
            );
        },
        [selectedProjects, onProjectsChange],
    );

    const content = React.useMemo(
        () => (
            <Box sx={{ minWidth: 140 }}>
                <FormGroup>
                    {projects.map(project => (
                        <FormControlLabel
                            key={project}
                            control={
                                <Checkbox
                                    checked={selectedProjects.includes(project)}
                                    onChange={() => handleProjectChange(project)}
                                    size="small"
                                    sx={{
                                        "&.Mui-checked": {
                                            color: "secondary.main",
                                        },
                                    }}
                                />
                            }
                            label={<Typography variant="body2">{project}</Typography>}
                            sx={{ py: 0.5, pl: 1 }}
                        />
                    ))}
                </FormGroup>
            </Box>
        ),
        [selectedProjects, handleProjectChange],
    );

    return (
        <Filter
            icon={<Folder size={20} />}
            text={`Project${selectedProjects.length > 0 ? ` (${selectedProjects.length})` : ""}`}
            content={content}
        />
    );
};
