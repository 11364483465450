import React from "react";
import { Box, Typography } from "@mui/material";
import PDFIcon from "../assets/pdf-icon.svg";
import { FILE_EXTENSIONS_REGEX } from "../search/fileExtensions";

export interface CitedSourcePage {
    page: number;
    rank: number;
}

interface CitedSourceBoxProps {
    fileName: string;
    pages: CitedSourcePage[];
    onSelect: ((page: number | undefined) => void) | undefined;
}

export const CitedSourceBox: React.FC<CitedSourceBoxProps> = ({ fileName, pages, onSelect }) => {
    const handleSelectWholeCard = React.useMemo(
        () => (onSelect != null ? () => onSelect(undefined) : undefined),
        [onSelect],
    );
    return (
        <Box
            sx={{
                width: 240,
                height: 78,
                mr: 1,
                flexShrink: 0,
                flexGrow: 0,
                border: 1,
                borderColor: "grey.300",
                borderRadius: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                bgcolor: "white",
                p: 1,
                overflow: "hidden",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", columnGap: 2 }}>
                <Typography
                    variant="body2"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        alignSelf: "flex-start",
                        cursor: onSelect != null ? "pointer" : "default",
                        "&:hover": {
                            bgcolor: onSelect != null ? "action.hover" : "transparent",
                        },
                    }}
                    onClick={handleSelectWholeCard}
                >
                    {fileName.replace(FILE_EXTENSIONS_REGEX, "")}
                </Typography>
                <img src={PDFIcon} alt={"PDF icon"} width={24} height={24} />
            </Box>
            {pages.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                    }}
                >
                    {pages.length <= 3 ? (
                        pages.map(p => (
                            <CitedSourceBoxPage key={p.rank} page={p.page} rank={p.rank} onSelect={onSelect} />
                        ))
                    ) : (
                        <>
                            {pages.slice(0, 2).map(p => (
                                <CitedSourceBoxPage key={p.rank} page={p.page} rank={p.rank} onSelect={onSelect} />
                            ))}
                            <CitedSourceBoxPagePlus count={pages.length - 2} />
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

interface CitedSourceBoxPageProps {
    page: number;
    rank: number;
    onSelect: ((page: number | undefined) => void) | undefined;
}

const CitedSourceBoxPage: React.FC<CitedSourceBoxPageProps> = ({ page, rank, onSelect }) => {
    const handleClick = React.useMemo(() => (onSelect != null ? () => onSelect(page) : undefined), [onSelect, page]);
    return (
        <Typography
            onClick={handleClick}
            noWrap
            sx={{
                color: "neutrals.60",
                px: 0.25,
                border: 1,
                borderColor: "neutrals.20",
                borderRadius: 1,
                cursor: onSelect != null ? "pointer" : "default",
                display: "flex",
                alignItems: "center",
                columnGap: 0.5,
                "&:hover": {
                    bgcolor: onSelect != null ? "action.hover" : "transparent",
                },
            }}
        >
            <Box
                component="span"
                sx={{
                    color: "info.main",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >{`[${rank + 1}]`}</Box>
            <Box
                component="span"
                sx={{
                    color: "neutrals.60",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >{`Pg. ${page}`}</Box>
        </Typography>
    );
};

const CitedSourceBoxPagePlus: React.FC<{ count: number }> = ({ count }) => {
    return (
        <Typography
            sx={{
                color: "neutrals.60",
                px: 0.25,
                border: 1,
                borderColor: "neutrals.20",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                columnGap: 0.5,
            }}
        >
            <Box
                component="span"
                sx={{
                    color: "info.main",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >{`+${count}`}</Box>
        </Typography>
    );
};
