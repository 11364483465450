import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { Filter } from "./filter";
import { Calendar2, DocumentText, User } from "iconsax-react";
import { ProjectFilter } from "./projectFilter";

interface FiltersProps {
    projects: string[];
    onProjectsChange: (projects: string[]) => void;
    sx?: SxProps<Theme>;
}

export const Filters: React.FC<FiltersProps> = ({ projects, onProjectsChange, sx }) => {
    return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Box sx={[{ display: "flex", gap: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}>
            <Filter
                icon={<Calendar2 size={20} />}
                text="Anytime"
                content={undefined} // Empty content for now
            />
            <Filter
                icon={<User size={20} />}
                text="Created by"
                content={undefined} // Empty content for now
            />
            <Filter
                icon={<DocumentText size={20} />}
                text="File type"
                content={undefined} // Empty content for now
            />
            <ProjectFilter selectedProjects={projects} onProjectsChange={onProjectsChange} />
        </Box>
    );
};
