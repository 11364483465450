import * as React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { SearchInput } from "../shared/searchInput";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CitedSourceBox, CitedSourcePage } from "../shared/citedSourceBox";
import { BACKEND_URL } from "../backend-client/url";
import { CoreChatRouterRespondToUserResponse } from "../backend-client/generated";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions, currentUserQueryOptions } from "./queryOptions";
import {
    AssistantChatMessage,
    UserChatMessage,
    AssistantChatMessageSource,
    AssistantChatMessageSourcePage,
    ChatOutSchema,
} from "../backend-client/generated/types.gen";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import { useBoolean } from "../utils/hooks";
import { CitedText } from "../shared/citedText";
import { PDFPreview } from "../search/pdfPreview";
import { Close } from "@mui/icons-material";
import { ChatHeader } from "./chatHeader";
import { ChatSplitLayout } from "./chatSplitLayout";

export const ChatView: React.FC = () => {
    const { chatId } = useParams<{ chatId: string }>();
    const [selectedSourceInfo, setSelectedSourceInfo] = React.useState<
        { systemMessageIdx: number; sourceIdx: number; pageIdx: number | undefined } | undefined
    >(undefined);
    const [query, setQuery] = React.useState<string>("");
    const [lastQuery, setLastQuery] = React.useState<string | undefined>(undefined);
    const [isStreaming, setIsStreaming] = React.useState(false);
    const [streamedResponse, setStreamedResponse] = React.useState<string>("");
    const [streamedSources, setStreamedSources] = React.useState<AssistantChatMessageSource[] | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const scrollPositionRef = React.useRef(0);

    const {
        data: chat,
        isLoading,
        isError,
    } = useQuery({
        ...chatQueryOptions(chatId ?? ""),
        refetchInterval: isStreaming ? false : undefined,
        refetchOnWindowFocus: !isStreaming,
    });

    const queryClient = useQueryClient();

    const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const { data: currentUser } = useQuery(currentUserQueryOptions);

    const handleFetchResponse = React.useCallback(
        (submittedQuery: string) => {
            if (chatId == null || submittedQuery.trim().length === 0) {
                return;
            }
            if (currentUser?.tenant_id == null) {
                console.error("Tenant ID is not set.");
                return;
            }

            setIsStreaming(true);
            setStreamedResponse("");
            setStreamedSources(undefined);

            setLastQuery(submittedQuery);
            // TODO: Somehow this nukes the user message while streaming
            // Add user message immediately
            // queryClient.setQueryData(chatQueryOptions(chatId).queryKey, oldData => {
            //     const newUserMessage: UserChatMessage = {
            //         role: "user",
            //         content: submittedQuery.trim(),
            //     };
            //     if (oldData == null) {
            //         console.log("oldData is null");
            //         return {
            //             unique_id: chatId,
            //             name: "",
            //             created_at: new Date().toISOString(),
            //             updated_at: new Date().toISOString(),
            //             messages: [newUserMessage],
            //         };
            //     }
            //     console.log("oldData", oldData);
            //     console.log("newUserMessage", newUserMessage);
            //     return {
            //         ...oldData,
            //         messages: [...oldData.messages, newUserMessage],
            //     };
            // });

            const url = `${BACKEND_URL}/api/chat/respond/${chatId}?query=${encodeURIComponent(submittedQuery)}&shard=${currentUser?.tenant_id}`;

            const eventSource = new EventSource(url);

            eventSource.onmessage = event => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const data = JSON.parse(event.data) as CoreChatRouterRespondToUserResponse;
                switch (data.type) {
                    case "response_chunk":
                        setStreamedResponse(prev => prev + data.data);
                        break;
                    case "sources":
                        setStreamedSources(data.data.map(source => ({ name: source.file_name, pages: source.pages })));
                        break;
                    case "status":
                        if (data.data === "completed" || data.data === "no-results") {
                            setIsStreaming(false);
                        }
                        break;
                    case "citations": {
                        const usedRanks = new Set(data.data);
                        setStreamedSources(prev =>
                            prev != null
                                ? prev.map(s => ({
                                      ...s,
                                      pages: s.pages?.filter(p => usedRanks.has(p.rank)) ?? [],
                                  }))
                                : undefined,
                        );
                        break;
                    }
                    case "error":
                        console.error("Error streaming response:", data.data);
                        setIsStreaming(false);
                        break;
                }
            };

            eventSource.onerror = error => {
                console.error("EventSource failed:", error);
                eventSource.close();
                setIsStreaming(false);
            };
        },
        [chatId, currentUser?.tenant_id],
    );

    const handleSubmit = React.useCallback(() => {
        handleFetchResponse(query);
        setQuery("");
    }, [handleFetchResponse, query]);

    const handleSelectSource = React.useCallback(
        (systemMessageIdx: number, sourceIdx: number, pageIdx: number | undefined) => {
            setSelectedSourceInfo({ systemMessageIdx, sourceIdx, pageIdx });
        },
        [],
    );

    const handleClosePreview = React.useCallback(() => {
        setSelectedSourceInfo(undefined);
    }, []);

    const handlePageChange = React.useCallback((newPage: number) => {
        setSelectedSourceInfo(prev => {
            if (prev == null) return undefined;
            return { ...prev, pageIdx: newPage };
        });
    }, []);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) return "Please enter a message";
        if (isStreaming) return "Sending message…";
        if (isLoading) return "Loading chat…";
        return null;
    }, [query, isStreaming, isLoading]);

    // Effect for handling new chat queries
    React.useEffect(() => {
        if (searchParams.has("new")) {
            const storedQuery = sessionStorage.getItem(SESSION_STORAGE_NEW_CHAT_QUERY);
            if (storedQuery != null && storedQuery.trim().length > 0) {
                handleFetchResponse(storedQuery);
                sessionStorage.removeItem(SESSION_STORAGE_NEW_CHAT_QUERY);
                setSearchParams({}, { replace: true });
            }
        }
    }, [searchParams, handleFetchResponse, setSearchParams]);

    // Effect for handling streamed responses
    React.useEffect(() => {
        if (!isStreaming && streamedResponse.trim().length > 0 && chatId != null) {
            queryClient.setQueryData(chatQueryOptions(chatId).queryKey, oldData => {
                const lastUserMessage: UserChatMessage = {
                    role: "user",
                    content: lastQuery ?? "",
                };
                const newAssistantMessage: AssistantChatMessage = {
                    role: "assistant",
                    content: streamedResponse,
                    sources: streamedSources ?? [],
                };
                if (oldData == null) return oldData;
                return {
                    ...oldData,
                    messages: [...oldData.messages, lastUserMessage, newAssistantMessage],
                };
            });
            setStreamedResponse("");
            setLastQuery(undefined);
            setStreamedSources(undefined);
        }
    }, [chatId, isStreaming, queryClient, streamedResponse, streamedSources, lastQuery]);

    const selectedSource = React.useMemo((): AssistantChatMessageSource | undefined => {
        if (selectedSourceInfo?.sourceIdx == null && selectedSourceInfo?.systemMessageIdx == null) return undefined;
        const message = chat?.messages[selectedSourceInfo.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        return message.sources[selectedSourceInfo.sourceIdx];
    }, [chat?.messages, selectedSourceInfo?.systemMessageIdx, selectedSourceInfo?.sourceIdx]);

    const saveScrollPosition = React.useCallback(() => {
        if (scrollRef.current && !isStreaming) {
            scrollPositionRef.current = scrollRef.current.scrollTop;
        }
    }, [isStreaming]);

    const isInitialLoad = React.useRef(true);

    // React.useEffect(() => {
    //     if (scrollRef.current && !isInitialLoad.current) {
    //         console.log("restoring scroll position", scrollPositionRef.current);
    //         scrollRef.current.scrollTop = scrollPositionRef.current;
    //     }
    // }, [selectedSource]); // Add any other dependencies that trigger remounts

    React.useEffect(() => {
        if (scrollRef.current && chat?.messages.length && isInitialLoad.current && !scrollPositionRef.current) {
            console.log("scrolling to bottom on initial load", scrollRef.current.scrollHeight, scrollRef.current);
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
            isInitialLoad.current = false;
        }
    }, [chat?.messages.length, scrollRef]);

    const scrollToBottom = React.useCallback((element: HTMLDivElement) => {
        requestAnimationFrame(() => {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: "smooth",
            });
        });
    }, []);

    const debouncedScrollToBottom = React.useMemo(() => {
        let timeoutId: number;
        return (element: HTMLDivElement) => {
            if (timeoutId) {
                cancelAnimationFrame(timeoutId);
            }
            timeoutId = requestAnimationFrame(() => {
                scrollToBottom(element);
            });
        };
    }, [scrollToBottom]);

    React.useEffect(() => {
        if (isStreaming && scrollRef.current) {
            debouncedScrollToBottom(scrollRef.current);
        }
    }, [isStreaming, debouncedScrollToBottom, streamedResponse]);

    const patchedChat = React.useMemo(() => {
        if (isStreaming && lastQuery != null && chat != null) {
            const userMessage: UserChatMessage = { role: "user", content: lastQuery };
            return { ...chat, messages: [...chat.messages, userMessage] };
        }
        return chat;
    }, [lastQuery, chat, isStreaming]);

    if (chatId == null) {
        return (
            <SidebarLayout>
                <Typography>No chat selected</Typography>
            </SidebarLayout>
        );
    }

    const mainContent = (
        <ChatViewContent
            // scrollRef={scrollRef}
            // onScroll={saveScrollPosition}
            chat={patchedChat}
            isLoading={isLoading}
            isError={isError}
            isStreaming={isStreaming}
            streamedResponse={streamedResponse}
            streamedSources={streamedSources}
            query={query}
            disabledReason={disabledReason}
            onQueryChange={handleQueryChange}
            onSubmit={handleSubmit}
            onSourceSelect={handleSelectSource}
        />
    );

    const header = isLoading ? (
        <ChatHeader chatId={chatId} chatName={undefined} />
    ) : (
        <ChatHeader chatId={chatId} chatName={chat?.name ?? "Untitled Chat"} />
    );

    return (
        <SidebarLayout>
            {/* {selectedSource != null ? ( */}
            <ChatSplitLayout
                header={header}
                mainContent={mainContent}
                preview={
                    selectedSource && (
                        <PreviewSection
                            source={selectedSource}
                            page={selectedSourceInfo?.pageIdx ?? 1}
                            onPageChange={handlePageChange}
                            onClose={handleClosePreview}
                        />
                    )
                }
                mainContentProps={{
                    bgcolor: "primary.main",
                    borderRadius: 3,
                    border: 1,
                    borderColor: "neutrals.30",
                }}
                scrollRef={scrollRef}
                onScroll={saveScrollPosition}
            />
            {/* // ) : (
            //     <ChatLayout header={header}>{mainContent}</ChatLayout>
            // )} */}
        </SidebarLayout>
    );
};

const ChatViewContent: React.FC<{
    // scrollRef: React.RefObject<HTMLDivElement>;
    // onScroll: () => void;
    chat: ChatOutSchema | undefined;
    isLoading: boolean;
    isError: boolean;
    isStreaming: boolean;
    streamedResponse: string;
    streamedSources: AssistantChatMessageSource[] | undefined;
    query: string;
    disabledReason: string | null;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
    onSourceSelect: (systemMessageIdx: number, sourceIdx: number, pageIdx: number | undefined) => void;
}> = ({
    // scrollRef,
    // onScroll,
    chat,
    isLoading,
    isError,
    isStreaming,
    streamedResponse,
    streamedSources,
    query,
    disabledReason,
    onQueryChange,
    onSubmit,
    onSourceSelect,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "space-between",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0,
                // mb: 2,
                // height: "100%",
                bgcolor: "primary.main",
            }}
        >
            <Box
                // ref={scrollRef}
                // onScroll={onScroll}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                    px: 2,
                    py: 2,
                    // "&::-webkit-scrollbar": {
                    //     width: "14px",
                    // },
                    // "&::-webkit-scrollbar-thumb": {
                    //     backgroundColor: "#98A2B3",
                    //     borderRadius: "8px",
                    //     border: "3px solid #F4F2EF",
                    //     borderColor: "primary.main",
                    // },
                    // "&::-webkit-scrollbar-track": {
                    //     backgroundColor: "primary.main",
                    // },
                }}
            >
                {isLoading ? (
                    <Typography>Loading chat…</Typography>
                ) : isError ? (
                    <Typography>Error loading chat</Typography>
                ) : (
                    <>
                        {chat?.messages.map((message, index) =>
                            message.role === "user" ? (
                                <UserMessage key={index} content={message.content} />
                            ) : (
                                <SystemMessage
                                    key={index}
                                    content={message.content}
                                    sources={message.sources}
                                    systemMessageIdx={index}
                                    onSourceSelect={onSourceSelect}
                                />
                            ),
                        )}
                        {isStreaming && (
                            <SystemMessage
                                content={streamedResponse}
                                sources={streamedSources}
                                systemMessageIdx={chat?.messages.length ?? 0}
                                onSourceSelect={undefined}
                            />
                        )}
                    </>
                )}
            </Box>
            <SearchInput
                query={query}
                onQueryChange={onQueryChange}
                onQuerySubmit={onSubmit}
                disabledReason={disabledReason}
                multiline
                sx={{ maxWidth: "100%", position: "sticky", bottom: 0, left: 0, right: 0 }}
            />
            <Box
                sx={theme => ({
                    width: "100%",
                    height: theme.spacing(2),
                    position: "absolute",
                    bottom: "1px",
                    opacity: 1,
                    bgcolor: "primary.main",
                })}
            />
        </Box>
    );
};

// New components
const UserMessage: React.FC<{ content: string }> = ({ content }) => (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Box
            sx={{
                maxWidth: "75%",
                px: 2,
                py: 1.5,
                borderRadius: 2,
                bgcolor: "surface.75",
            }}
        >
            <Typography whiteSpace="pre-wrap">{content}</Typography>
        </Box>
    </Box>
);

const SystemMessage: React.FC<{
    content: string;
    sources: AssistantChatMessageSource[] | undefined;
    systemMessageIdx: number;
    onSourceSelect: ((systemMessageIdx: number, sourceIdx: number, pageIdx: number | undefined) => void) | undefined;
}> = ({ content, sources, systemMessageIdx, onSourceSelect }) => {
    const { value: showSources, toggleValue: toggleShowSources } = useBoolean(true);

    const handleSourceSelect = React.useMemo(
        () =>
            onSourceSelect != null
                ? (sourceIdx: number, pageIdx: number | undefined) =>
                      onSourceSelect(systemMessageIdx, sourceIdx, pageIdx)
                : undefined,
        [onSourceSelect, systemMessageIdx],
    );

    const handleSelectSourceByRank = React.useCallback(
        (rank: number) => {
            if (onSourceSelect == null) {
                return;
            }
            const source = sources
                ?.flatMap((s, sourceIdx) => s.pages?.map(p => ({ sourceIdx, page: p })))
                .find(p => p?.page.rank === rank);
            if (source != null) {
                onSourceSelect(systemMessageIdx, source.sourceIdx, source.page.page);
            }
        },
        [onSourceSelect, sources, systemMessageIdx],
    );

    const theme = useTheme();
    const hasSources = sources != null && sources.length > 0;
    return (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
            <Box
                sx={{
                    maxWidth: "75%",
                    p: 2,
                    display: "flex",
                    overflowX: "hidden",
                    gap: 1,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "start", mb: 1 }}>
                    <Logo style={{ width: 24, height: 24, marginRight: 8 }} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", gap: 1 }}>
                    {/* n relevant docs in muted text */}
                    {hasSources && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1" color="neutrals.50">
                                {sources.length} relevant {sources.length === 1 ? "doc" : "docs"}
                            </Typography>
                            <IconButton size="small" onClick={toggleShowSources}>
                                {showSources ? (
                                    <ArrowDown2 size={12} color={theme.palette.neutrals[50]} />
                                ) : (
                                    <ArrowRight2 size={12} color={theme.palette.neutrals[50]} />
                                )}
                            </IconButton>
                        </Box>
                    )}
                    {showSources && hasSources && (
                        <Box sx={{ display: "flex", columnGap: 1, alignItems: "center", overflowX: "auto", mt: 1 }}>
                            {sources?.map((source, idx) => (
                                // TODO: Add pages
                                <ChatCitedSourceBox
                                    key={idx}
                                    fileName={source.name}
                                    pages={source.pages ?? []}
                                    sourceIdx={idx}
                                    onSelect={handleSourceSelect}
                                />
                            ))}
                        </Box>
                    )}
                    <CitedText text={content} onCitationClick={handleSelectSourceByRank} />
                </Box>
            </Box>
        </Box>
    );
};

interface ChatCitedSourceBoxProps {
    fileName: string;
    pages: AssistantChatMessageSourcePage[];
    sourceIdx: number;
    onSelect: ((sourceIdx: number, pageIdx: number | undefined) => void) | undefined;
}

const ChatCitedSourceBox: React.FC<ChatCitedSourceBoxProps> = ({ fileName, pages, sourceIdx, onSelect }) => {
    const handleSelect = React.useCallback(
        (pageIdx: number | undefined) => (onSelect != null ? onSelect(sourceIdx, pageIdx) : undefined),
        [onSelect, sourceIdx],
    );
    const citedSourcePages = React.useMemo((): CitedSourcePage[] => {
        return pages != null ? pages.map<CitedSourcePage>(p => ({ page: p.page, rank: p.rank })) : [];
    }, [pages]);

    return <CitedSourceBox fileName={fileName} pages={citedSourcePages} onSelect={handleSelect} />;
};

const PreviewSection: React.FC<{
    source: AssistantChatMessageSource;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
}> = ({ source, page, onPageChange, onClose }) => {
    const previewSource = React.useMemo(() => {
        return { file_name: source.name, pages: source.pages ?? [] };
    }, [source]);

    return (
        <Box sx={{ height: "100%", py: 3, px: 5 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2, columnGap: 2 }}>
                <Typography variant="h6" color="secondary.main" noWrap>
                    {source.name}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Close sx={{ width: 20, height: 20 }} />
                </IconButton>
            </Box>
            <PDFPreview source={previewSource} page={page} onPageChange={onPageChange} />
        </Box>
    );
};
