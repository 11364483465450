import React, { useState, useCallback, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import Popover from "@mui/material/Popover";

interface FilterProps {
    icon: React.ReactNode;
    text: string;
    content: React.ReactNode | undefined;
}

export const Filter: React.FC<FilterProps> = ({ icon, text, content }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            if (content) {
                setAnchorEl(event.currentTarget);
            }
        },
        [content],
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);

    return (
        <>
            <Box
                ref={buttonRef}
                onClick={handleClick}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "surface.75",
                    borderRadius: 1,
                    width: 144,
                    px: 1,
                    cursor: content ? "pointer" : "not-allowed",
                    "&:hover": {
                        backgroundColor: content ? "surface.100" : undefined,
                    },
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, py: 0.5, px: 0.25 }}>
                    {icon}
                    <Typography variant="body2">{text}</Typography>
                </Box>
                <ArrowDown2 size={16} />
            </Box>
            {content && (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <Box sx={{ p: 0.5, typography: "body2" }}>{content}</Box>
                </Popover>
            )}
        </>
    );
};
