import * as React from "react";
import { Typography, Box } from "@mui/material";
import { ChatLayout } from "./chatLayout";
import { SearchInput } from "../shared/searchInput";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { coreChatRouterCreateChat } from "../backend-client/generated/services.gen";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions } from "./queryOptions";

export const NewChatView: React.FC = () => {
    const [query, setQuery] = React.useState<string>("");

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: () =>
            coreChatRouterCreateChat({
                throwOnError: true,
                body: {
                    file_scope: searchParams.get("sources")?.split(",") ?? undefined,
                },
            }),
        onSuccess: response => {
            sessionStorage.setItem(SESSION_STORAGE_NEW_CHAT_QUERY, query);
            // if (searchParams.has("sources")) {
            //     sessionStorage.setItem(SESSION_STORAGE_FOCUSED_SEARCH_FILE_PATHS, searchParams.get("sources") ?? "");
            // }

            if (response.data.unique_id != null) {
                queryClient.setQueryData(chatQueryOptions(response.data.unique_id).queryKey, response.data);
                navigate(`/chat/${response.data.unique_id}?new`);
            } else {
                console.error("No unique_id returned from create chat");
            }
        },
        onError: error => {
            console.error("Error creating chat:", error);
        },
    });

    const handleQueryChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(event.target.value);
        },
        [setQuery],
    );

    const handleQuerySubmit = React.useCallback(() => {
        mutate();
    }, [mutate]);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) {
            return "Please enter a message";
        }
        if (isPending) {
            return "Creating chat...";
        }
        return null;
    }, [query, isPending]);

    return (
        <SidebarLayout>
            <ChatLayout>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexGrow: 1,
                        flexShrink: 1,
                        minWidth: 0,
                        mb: 2,
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", pt: "30%", rowGap: 2 }}>
                        <Logo style={{ width: 64, height: 64, marginBottom: 2 }} />
                        <Typography variant="h6" sx={{ mb: 2, color: "text.secondary" }}>
                            Explore valuable insights
                        </Typography>
                    </Box>
                    <SearchInput
                        query={query}
                        onQueryChange={handleQueryChange}
                        onQuerySubmit={handleQuerySubmit}
                        disabledReason={disabledReason}
                        multiline
                        sx={{ width: "600px" }}
                    />
                </Box>
            </ChatLayout>
        </SidebarLayout>
    );
};
