import * as React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Sources } from "./types";
import { useResizeDetector } from "react-resize-detector";
import { CitedSourceBox } from "../shared/citedSourceBox";
import { ArrowUp2 } from "iconsax-react";
import { CitedSourcePage } from "../shared/citedSourceBox";
import { ArrowDown2 } from "iconsax-react";
import { CitedText } from "../shared/citedText";

interface TextAnswerSectionProps {
    aiAnswer: string | undefined;
    citedSources: Sources | undefined;
    onSelectSource: (idx: number, page: number | undefined) => void;
    isStreamingAnswer: boolean;
    isExpanded: boolean;
    onExpandedChange: (expanded: boolean) => void;
}

const MAX_HEIGHT = 152;
// const GRADIENT_HEIGHT = 40;

const NON_TEXT_HEIGHT = 90;

export const TextAnswerSection: React.FC<TextAnswerSectionProps> = ({
    aiAnswer,
    citedSources,
    onSelectSource,
    isStreamingAnswer,
    isExpanded,
    onExpandedChange,
}) => {
    const { height, ref } = useResizeDetector();
    const theme = useTheme();
    const shouldShowExpandButton = !isStreamingAnswer || (height != null && height > MAX_HEIGHT - 5 - NON_TEXT_HEIGHT);

    const handleToggleExpand = React.useCallback(() => {
        onExpandedChange(!isExpanded);
    }, [isExpanded, onExpandedChange]);

    const handleCitationClick = React.useCallback(
        (rank: number) => {
            const sourcePages = citedSources?.flatMap((s, sourceIdx) => s.pages.map(p => ({ sourceIdx, page: p })));
            const clickedPage = sourcePages?.find(s => s.page.rank === rank);
            if (clickedPage == null) {
                return;
            }
            onSelectSource(clickedPage.sourceIdx, clickedPage.page.page);
        },
        [citedSources, onSelectSource],
    );

    return (
        <Box
            sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                rowGap: 2,
                position: "relative",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    width: "100%",
                    maxHeight: isExpanded ? "none" : MAX_HEIGHT,
                    overflow: "hidden",
                    transition: "max-height 0.3s ease-out",
                    bgcolor: "white",
                    px: 1.5,
                    py: 1.5,
                    borderRadius: 2,
                    border: 1,
                    borderColor: "neutrals.25",
                }}
            >
                <Typography
                    ref={ref}
                    variant="body2"
                    sx={{
                        // maxHeight: isExpanded ? "none" : MAX_HEIGHT,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: isExpanded ? "none" : 3,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    <CitedText text={aiAnswer ?? ""} onCitationClick={handleCitationClick} />
                </Typography>
                {isExpanded && (
                    <Box sx={{ display: "flex", overflowX: "auto", mt: 2 }}>
                        {citedSources?.map((source, index) => (
                            <TextAnswerSectionCitedSourceBox
                                key={index}
                                fileName={source.file_name}
                                pages={source.pages}
                                sourceIdx={index}
                                onSelect={onSelectSource}
                            />
                        ))}
                    </Box>
                )}
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleToggleExpand}
                    sx={{
                        textTransform: "none",
                        borderColor: "neutrals.30",
                        transition: "all 0.5s ease-in-out",
                        visibility: shouldShowExpandButton ? "visible" : "hidden",
                        opacity: shouldShowExpandButton ? 1 : 0,
                        height: shouldShowExpandButton ? "auto" : 0,
                        "&:hover": {
                            bgcolor: "action.hover",
                            borderColor: "neutrals.30",
                        },
                        mt: isExpanded ? 2 : 1.5,
                    }}
                >
                    <Typography variant="caption" color="secondary.main" fontWeight={500} sx={{ mr: 0.25 }}>
                        {isExpanded ? "Show less" : "Show more"}
                    </Typography>
                    {isExpanded ? (
                        <ArrowUp2 variant="Bold" size={12} color={theme.palette.secondary.main} />
                    ) : (
                        <ArrowDown2 variant="Bold" size={12} color={theme.palette.secondary.main} />
                    )}
                </Button>
            </Box>
        </Box>
    );
};

interface TextAnswerSectionCitedSourceBoxProps {
    fileName: string;
    pages: CitedSourcePage[];
    sourceIdx: number;
    onSelect: (sourceIdx: number, page: number | undefined) => void;
}

const TextAnswerSectionCitedSourceBox: React.FC<TextAnswerSectionCitedSourceBoxProps> = ({
    fileName,
    pages,
    sourceIdx,
    onSelect,
}) => {
    const handleSelect = React.useCallback(
        (page: number | undefined) => onSelect(sourceIdx, page),
        [sourceIdx, onSelect],
    );
    return <CitedSourceBox fileName={fileName} pages={pages} onSelect={handleSelect} />;
};
