import React, { useState, useCallback } from "react";
import { Box, Skeleton, styled, SxProps, Theme } from "@mui/material";
import PDFIcon from "../assets/pdf-icon.svg";
import DocIcon from "../assets/docx-icon.png";
import { coreBlobRouterGenerateSasUrl } from "../backend-client/generated/services.gen";
import { useQuery } from "@tanstack/react-query";

interface DocumentThumbnailProps {
    fileName: string;
    fileType: "pdf" | "docx" | undefined;
    sx?: SxProps<Theme>;
    onClick?: () => void;
    width?: number;
    height?: number;
}

const FILE_EXTENSIONS = ["pdf", "docx", "pptx", "xlsx", "txt", "doc"];

const StyledImg = styled("img")({});

export const DocumentThumbnail = React.memo<DocumentThumbnailProps>(
    ({ fileName, fileType, sx, onClick, width = 35, height = 43 }: DocumentThumbnailProps) => {
        const [error, setError] = useState<boolean>(false);
        const handleError = useCallback(() => {
            console.error("Error fetching image URL");
            setError(true);
        }, []);

        const thumbnailQuery = useQuery({
            queryKey: ["documentThumbnail", fileName],
            queryFn: async () => {
                const hasFileEnding = FILE_EXTENSIONS.some(ending => fileName.endsWith(ending));
                const baseFileName = hasFileEnding ? fileName.substring(0, fileName.lastIndexOf(".")) : fileName;
                const jpegFileName = `${baseFileName}.jpg`;

                const response = await coreBlobRouterGenerateSasUrl({
                    throwOnError: true,
                    path: { blob_name: jpegFileName },
                    query: {
                        container_type: "images",
                    },
                });

                return response.data.url;
            },
        });

        const fallbackIconUrl = fileType === "pdf" ? PDFIcon : DocIcon;
        const fallbackIconAlt = fileType === "pdf" ? "PDF icon" : "DOCX icon";

        const patchedError = error || thumbnailQuery.isError;

        return (
            <Box
                sx={[
                    { width, height, borderRadius: 4, position: "relative" },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(sx) ? sx : [sx]),
                ]}
                onClick={onClick}
            >
                {patchedError ? (
                    <img
                        src={fallbackIconUrl}
                        alt={fallbackIconAlt}
                        width={width}
                        // height={height}
                        style={{ objectFit: "cover" }}
                    />
                ) : thumbnailQuery.data != null ? (
                    <Box sx={{ position: "relative", width, height }}>
                        <StyledImg
                            src={thumbnailQuery.data}
                            alt={fileName}
                            width={width}
                            height={height}
                            // style={{ objectFit: "cover", borderRadius: 4, border: 1, borderColor: "black" }}
                            sx={{ objectFit: "cover", borderRadius: 1, border: 1, borderColor: "neutrals.10" }}
                            onError={handleError}
                        />
                        <Box
                            component="img"
                            src={fallbackIconUrl}
                            alt={fallbackIconAlt}
                            sx={{
                                position: "absolute",
                                bottom: 2,
                                right: 2,
                                // width: 12,
                                height: fileType === "pdf" ? 18 : 14,
                            }}
                        />
                    </Box>
                ) : (
                    <Skeleton variant="rectangular" width={width} height={height} />
                )}
            </Box>
        );
    },
);

DocumentThumbnail.displayName = "DocumentThumbnail";
