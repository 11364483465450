import Box from "@mui/material/Box";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Skeleton, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarRight, SidebarLeft, Home, Message2, Messages2, Logout, Login } from "iconsax-react";
import "../App.css";
// import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
// import SpurIcon from "../assets/spur.png";
import { useQuery } from "@tanstack/react-query";
import { chatsQueryOptions, currentUserQueryOptions, tenantQueryOptions } from "../chat/queryOptions";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { ChatListSchema } from "../backend-client/generated";
import { comparatorOnFields } from "../utils/comparators";

const SIDEBAR_COLLAPSED_LOCAL_STORAGE_KEY = "newton.sidebarCollapsed";

export const Sidebar: React.FC = () => {
    const [isCollapsed, setIsCollapsed] = React.useState(
        localStorage.getItem(SIDEBAR_COLLAPSED_LOCAL_STORAGE_KEY) !== "false",
    );
    const navigate = useNavigate();

    const toggleSidebar = React.useCallback(() => {
        const newCollapsedValue = !isCollapsed;
        setIsCollapsed(newCollapsedValue);
        localStorage.setItem(SIDEBAR_COLLAPSED_LOCAL_STORAGE_KEY, newCollapsedValue.toString());
    }, [isCollapsed]);

    const handleClickNewTab = React.useCallback(() => {
        navigate("/");
    }, [navigate]);

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if CMD+G on Mac or Ctrl+G on Windows/Linux
            if ((event.ctrlKey || event.metaKey) && event.key === "g") {
                event.preventDefault();
                handleClickNewTab();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleClickNewTab]);

    const handleSignOut = React.useCallback(() => {
        if (window.confirm("Are you sure you want to sign out?")) {
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
            navigate("/login");
        }
    }, [navigate]);

    const user = useQuery(currentUserQueryOptions);

    const isSignedIn = React.useMemo(() => {
        return user.data != null || localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) != null;
    }, [user.data]);

    const handleSignIn = React.useCallback(() => {
        navigate("/login");
    }, [navigate]);

    const location = useLocation();

    const isChatSelected = React.useMemo(() => {
        return location.pathname.startsWith("/chat");
    }, [location.pathname]);

    const theme = useTheme();

    const iconColor = theme.palette.neutrals[50];

    const { data: chats } = useQuery(chatsQueryOptions);
    const hasPreviousChats = chats != null && chats.length > 0;

    return (
        <>
            <Box
                sx={{
                    pt: 3,
                    flex: "0 0 auto",
                    flexBasis: isCollapsed ? 64 : 240,
                    minHeight: 0,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    transition: "flex-basis 0.3s ease, background-color 0.3s ease",
                    bgcolor: "primary.main",
                    border: 1,
                    borderRadius: 3,
                    borderColor: "neutrals.30",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        px: 2,
                        overflowY: "hidden",
                        rowGap: 3,
                        overflowX: "hidden",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: isCollapsed ? "center" : "stretch",
                            overflowX: "hidden",
                        }}
                    >
                        <CustomerComponent isCollapsed={isCollapsed} />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: isCollapsed ? "center" : "stretch",
                            maxWidth: "100%",
                            rowGap: 2,
                            overflow: "hidden",
                            transition: "align-items 0.3s ease",
                            overflowX: "hidden",
                        }}
                    >
                        <HomeButton isCollapsed={isCollapsed} />
                        {(isChatSelected || hasPreviousChats) && <ChatButton isCollapsed={isCollapsed} />}
                    </Box>
                </Box>
                <Box
                    sx={{
                        mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        borderTop: isCollapsed ? 0 : 1,
                        pt: 1,
                        px: 1,
                        borderColor: "#E4E7EC",
                        rowGap: 1, // Add some space between buttons
                    }}
                >
                    <ActionButton
                        isCollapsed={isCollapsed}
                        icon={
                            isCollapsed ? (
                                <SidebarRight color={iconColor} size="20" />
                            ) : (
                                <SidebarLeft color={iconColor} size="20" />
                            )
                        }
                        text={isCollapsed ? "Expand" : "Collapse"}
                        onClick={toggleSidebar}
                    />
                    {isSignedIn ? (
                        <ActionButton
                            isCollapsed={isCollapsed}
                            icon={<Logout color={iconColor} size="20" />}
                            text="Sign Out"
                            onClick={handleSignOut}
                        />
                    ) : (
                        <ActionButton
                            isCollapsed={isCollapsed}
                            icon={<Login color={iconColor} size="20" />}
                            text="Sign In"
                            onClick={handleSignIn}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};

interface HomeButtonProps {
    isCollapsed: boolean;
}

const HomeButton: React.FC<HomeButtonProps> = ({ isCollapsed }) => {
    const theme = useTheme();

    const navigate = useNavigate();
    const handleClickHome = React.useCallback(() => {
        navigate("/");
    }, [navigate]);

    const location = useLocation();
    const isSelected = React.useMemo(() => {
        return location.pathname === "/" || location.pathname === "/search";
    }, [location.pathname]);

    if (isCollapsed) {
        return (
            <IconButton aria-label="Home" onClick={handleClickHome} size="small" sx={{ mx: 1 }}>
                <Home color={theme.palette.secondary.main} variant={isSelected ? "Bold" : "Outline"} size={20} />
            </IconButton>
        );
    }

    return (
        <Button
            startIcon={
                <Home color={theme.palette.secondary.main} variant={isSelected ? "Bold" : "Outline"} size={20} />
            }
            onClick={handleClickHome}
            sx={{
                color: "secondary.main",
                justifyContent: "flex-start",
            }}
        >
            Home
        </Button>
    );
};

interface CustomerComponentProps {
    isCollapsed: boolean;
}

const CustomerComponent: React.FC<CustomerComponentProps> = ({ isCollapsed }) => {
    const theme = useTheme();

    const { data: tenant, isLoading: isTenantLoading } = useQuery(tenantQueryOptions);

    if (isCollapsed) {
        if (isTenantLoading) {
            return <Skeleton variant="circular" width={20} height={20} />;
        } else if (tenant != null && tenant.logo_url != null) {
            return <img src={tenant.logo_url} alt={tenant.name} style={{ width: 20, height: 20, flexShrink: 0 }} />;
        }
        return <></>;
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    pr: 1,
                    pl: 1.5,
                    py: 1,
                    borderRadius: 3.5,
                    columnGap: 1,
                    bgcolor: "primary.main",
                    typography: "body2",
                    alignItems: "center",
                    fontWeight: "semibold",
                    color: theme.palette.neutrals[80],
                    border: 1,
                    borderColor: "divider",
                    justifyContent: "space-between",
                    // cursor: "pointer",
                }}
                // onClick={handleClick}
            >
                <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                    {isTenantLoading ? (
                        <Skeleton variant="circular" width={20} height={20} />
                    ) : tenant != null && tenant.logo_url != null ? (
                        <img src={tenant.logo_url} alt={tenant.name} style={{ width: 20, height: 20, flexShrink: 0 }} />
                    ) : (
                        <></>
                    )}
                    {isTenantLoading ? <Skeleton variant="rectangular" width={50} height={20} /> : tenant?.name}
                </Box>
                {/* <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                    <More color={theme.palette.neutrals[60]} size={16} />
                </Box> */}
            </Box>
        </>
    );
};

interface ActionButtonProps {
    isCollapsed: boolean;
    icon: React.ReactNode;
    text: string;
    href?: string;
    onClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ isCollapsed, icon, text, href, onClick }) => {
    if (isCollapsed) {
        return (
            <IconButton aria-label={text} onClick={onClick} size="small" sx={{ mx: 1 }}>
                {icon}
            </IconButton>
        );
    } else {
        return (
            <Button
                href={href}
                onClick={onClick}
                startIcon={icon}
                color="primary"
                sx={{
                    justifyContent: "center",
                    mx: 1,
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "nowrap",
                    color: "primary.contrastText",
                    textTransform: "none",
                    typography: "caption",
                }}
            >
                {text}
            </Button>
        );
    }
};

interface ChatButtonProps {
    isCollapsed: boolean;
}

const ChatButton: React.FC<ChatButtonProps> = ({ isCollapsed }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickChat = React.useCallback(() => {
        navigate("/chat");
    }, [navigate]);

    const isSelected = React.useMemo(() => {
        return location.pathname.startsWith("/chat");
    }, [location.pathname]);

    const selectedChatId = React.useMemo(() => {
        return location.pathname.split("/").pop();
    }, [location.pathname]);

    const { data: chats } = useQuery(chatsQueryOptions);

    const sortedChats = React.useMemo(() => {
        return chats?.sort(comparatorOnFields(chat => [-new Date(chat.updated_at).getTime()]));
    }, [chats]);

    if (isCollapsed) {
        return (
            <IconButton aria-label="Chat" onClick={handleClickChat} size="small" sx={{ mx: 1 }}>
                <Message2 color={theme.palette.secondary.main} variant={isSelected ? "Bold" : "Outline"} size={20} />
            </IconButton>
        );
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Button
                onClick={handleClickChat}
                startIcon={
                    <Messages2
                        color={theme.palette.secondary.main}
                        variant={isSelected ? "Bold" : "Outline"}
                        size={20}
                    />
                }
                sx={{
                    color: "secondary.main",
                    justifyContent: "flex-start",
                    borderRadius: 0,
                    pb: 1,
                    mb: 1,
                }}
            >
                Chat
            </Button>
            {sortedChats != null && sortedChats.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: 2,
                        maxHeight: 250,
                        overflowX: "hidden",
                        "&::-webkit-scrollbar": {
                            width: "14px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#98A2B3",
                            borderRadius: "8px",
                            border: "3px solid #F4F2EF",
                            borderColor: "primary.main",
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "primary.main",
                        },
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ minWidth: "1px", bgcolor: "divider", mr: 1.5 }} />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                overflowY: "auto",
                                // TODO: Hack to fix alignment with divider
                                mt: -1,
                            }}
                        >
                            {sortedChats.map(chat => (
                                <PreviousChatButton chat={chat} selectedChatId={selectedChatId} key={chat.unique_id} />
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const PreviousChatButton: React.FC<{ chat: ChatListSchema; selectedChatId: string | undefined }> = ({
    chat,
    selectedChatId,
}) => {
    const navigate = useNavigate();
    const handleClick = React.useCallback(() => {
        navigate(`/chat/${chat.unique_id}`);
    }, [navigate, chat.unique_id]);

    return (
        <Button
            onClick={handleClick}
            sx={{
                width: "100%",
                justifyContent: "flex-start",
                color: selectedChatId === chat.unique_id ? "secondary.main" : "text.secondary",
                py: 0.5,
                typography: "body2",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            <Typography variant="body2" noWrap>
                {chat.name}
            </Typography>
        </Button>
    );
};
