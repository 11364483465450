import React from "react";
import { Box, Container } from "@mui/material";
interface ChatLayoutProps {
    children: React.ReactNode;
    header?: React.ReactNode;
}

export const ChatLayout: React.FC<ChatLayoutProps> = ({ children, header }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                bgcolor: "surface.50",
                height: "100%",
                overflowY: "hidden",
                rowGap: 1,
            }}
        >
            <Box sx={{ border: 1, borderColor: "neutrals.30", borderRadius: 3 }}>{header}</Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "primary.main",
                    borderRadius: 3,
                    border: 1,
                    borderColor: "neutrals.30",
                    flexGrow: 1,
                    overflowY: "hidden",
                }}
            >
                <Container
                    maxWidth="md"
                    sx={{
                        display: "flex",
                        justifyContent: "stretch",
                        alignItems: "stretch",
                        minHeight: 0,
                        flexGrow: 1,
                    }}
                >
                    {children}
                </Container>
            </Box>
        </Box>
    );
};
