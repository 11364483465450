import React from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "./isAuthenticated";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { coreAuthRouterGetToken } from "../backend-client/generated/services.gen";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { H } from "highlight.run";
import { coreAuthRouterGetCurrentUser } from "../backend-client/generated/services.gen";

export const LoginView: React.FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    React.useEffect(() => {
        if (isAuthenticated()) {
            navigate("/");
        }
    }, [navigate]);

    const { mutate, isPending, error } = useMutation({
        mutationFn: () =>
            coreAuthRouterGetToken({
                throwOnError: true,
                body: { username, password },
                credentials: "include",
            }),
        onSuccess: async response => {
            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, response.data.token);

            try {
                const userResponse = await coreAuthRouterGetCurrentUser({
                    throwOnError: true,
                    credentials: "include",
                });

                // Identify user in Highlight
                H.identify(userResponse.data.username, {
                    email: userResponse.data.email,
                    id: userResponse.data.id.toString(),
                });
            } catch (error) {
                console.error("Failed to fetch user details:", error);
            }

            navigate("/");
        },
        onError: error => {
            console.error("Login error:", error);
        },
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        mutate();
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "300px",
                    gap: 2,
                }}
            >
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Login
                </Typography>
                <TextField
                    label="Username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    fullWidth
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" disabled={isPending} sx={{ mt: 2 }}>
                    {isPending ? "Logging in…" : "Login"}
                </Button>
                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        Login failed. Please try again.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
