import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";

interface ProtectedRouteProps {
    authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ authenticationPath }) => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

    if (token != null) {
        return <Outlet />;
    } else {
        return <Navigate to={authenticationPath} />;
    }
};
