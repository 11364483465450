import * as React from "react";
import { Typography, Container, Box } from "@mui/material";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { useNavigate } from "react-router-dom";
import { Filters } from "./filters";
import { SearchInput } from "../shared/searchInput";
import { getSearchUrl } from "./url";

export const SearchHomeView: React.FC = () => {
    const [query, setQuery] = React.useState<string>("");
    const [projects, setProjects] = React.useState<string[]>([]);
    const navigate = useNavigate();

    const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const handleQuerySubmit = React.useCallback(() => {
        navigate(getSearchUrl(query, projects));
    }, [query, navigate, projects]);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) {
            return "Please enter a search query";
        }
        // Add more conditions here if needed
        return null;
    }, [query]);

    return (
        <SidebarLayout>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    bgcolor: "primary.main",
                    borderRadius: 3,
                    border: 1,
                    borderColor: "neutrals.30",
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        mt: "14%",
                        minWidth: "712px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        rowGap: 2,
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 2 }}>
                        <Logo style={{ width: 56, height: 56 }} />
                        <Typography variant="h3" sx={{ color: "secondary.main", fontFamily: "Garamond-Light-Cond" }}>
                            Answers at scale
                        </Typography>
                    </Box>
                    <SearchInput
                        query={query}
                        onQueryChange={handleQueryChange}
                        onQuerySubmit={handleQuerySubmit}
                        disabledReason={disabledReason}
                    />
                    <Filters projects={projects} onProjectsChange={setProjects} />
                </Container>
            </Box>
        </SidebarLayout>
    );
};
