import * as React from "react";
import { Box, IconButton, Input, Theme, SxProps, Tooltip } from "@mui/material";
import { ArrowUp, Paperclip } from "iconsax-react";
import { useTheme } from "@mui/material/styles";

interface SearchInputProps {
    query: string;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onQuerySubmit: () => void;
    disabledReason: string | null;
    multiline?: boolean;
    sx?: SxProps<Theme>;
}

export const SearchInput: React.FC<SearchInputProps> = ({
    query,
    onQueryChange,
    onQuerySubmit,
    disabledReason,
    multiline = false,
    sx,
}) => {
    const theme = useTheme();

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                onQuerySubmit();
            }
        },
        [onQuerySubmit],
    );

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "white",
                    borderRadius: 2,
                    border: 1,
                    borderColor: "#DEDEDE",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Input
                autoFocus
                placeholder="Find answers"
                value={query}
                startAdornment={
                    <IconButton size="small" disableRipple sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                        <Paperclip size={24} color={theme.palette.neutrals[80]} />
                    </IconButton>
                }
                onChange={onQueryChange}
                onKeyDown={handleKeyDown}
                fullWidth
                disableUnderline
                multiline={multiline}
                maxRows={multiline ? 7 : undefined}
                sx={{ pr: 1.5, height: multiline ? "auto" : 48 }}
            />
            <Tooltip title={disabledReason || ""}>
                <span style={{ alignSelf: "stretch", display: "flex", alignItems: "stretch" }}>
                    <IconButton sx={{ alignSelf: "stretch" }} disabled={disabledReason != null} onClick={onQuerySubmit}>
                        <Box
                            sx={{
                                bgcolor: disabledReason != null ? "divider" : "secondary.main",
                                borderRadius: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: 24,
                                height: 24,
                            }}
                        >
                            <ArrowUp size={24} color={theme.palette.common.white} />
                        </Box>
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    );
};
