import * as React from "react";
import { Box, IconButton, Typography, useTheme, Avatar, TextField, Skeleton } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { chatQueryOptions, chatsQueryOptions, currentUserQueryOptions } from "./queryOptions";
import { ArrowLeft2, Edit2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { coreChatRouterUpdateChat } from "../backend-client/generated/services.gen";
import { IosShare } from "@mui/icons-material";
import { ShareDialog } from "./ShareDialog";

interface ChatHeaderProps {
    /**
     * The name of the chat.
     * If undefined, the chat is being loaded.
     */
    chatName: string | undefined;
    /**
     * The ID of the chat.
     */
    chatId: string;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({ chatName, chatId }) => {
    const [isEditing, setIsEditing] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [editedName, setEditedName] = React.useState(chatName);
    const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);

    const queryClient = useQueryClient();

    const updateChatMutation = useMutation({
        mutationFn: ({ newName }: { newName: string }) =>
            coreChatRouterUpdateChat({
                path: { chat_id: chatId },
                body: { name: newName, messages: null },
            }),
        onSuccess: (_, { newName }) => {
            const singleQueryOptions = chatQueryOptions(chatId);
            void queryClient.setQueryData(singleQueryOptions.queryKey, oldData => {
                if (oldData == null) {
                    return undefined;
                }
                return { ...oldData, updated_at: new Date().toISOString(), name: newName };
            });
            const multipleQueryOptions = chatsQueryOptions;
            void queryClient.setQueryData(multipleQueryOptions.queryKey, oldData => {
                if (oldData == null) {
                    return undefined;
                }
                return oldData.map(chat =>
                    chat.unique_id === chatId ? { ...chat, updated_at: new Date().toISOString(), name: newName } : chat,
                );
            });
            setIsEditing(false);
        },
    });

    const handleEditClick = React.useCallback(() => {
        setIsEditing(true);
        setEditedName(chatName);
    }, [chatName]);

    const handleEditComplete = React.useCallback(() => {
        if (editedName != null && editedName.trim() !== "" && editedName !== chatName) {
            updateChatMutation.mutate({ newName: editedName });
        } else {
            setIsEditing(false);
        }
    }, [editedName, chatName, updateChatMutation]);

    const navigate = useNavigate();
    const handleGoBack = React.useCallback(() => {
        navigate("/home");
    }, [navigate]);

    const theme = useTheme();

    const currentUserQuery = useQuery(currentUserQueryOptions);

    const initials = React.useMemo(() => {
        if (currentUserQuery.data == null) {
            return undefined;
        }
        return currentUserQuery.data.first_name.charAt(0) + currentUserQuery.data.last_name.charAt(0);
    }, [currentUserQuery.data]);

    const handleMouseEnter = React.useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = React.useCallback(() => {
        setIsHovered(false);
    }, []);

    const handleChangeChatName = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedName(e.target.value);
    }, []);

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                handleEditComplete();
            }
        },
        [handleEditComplete],
    );

    const handleShare = React.useCallback(() => {
        setIsShareDialogOpen(true);
    }, []);

    const handleCloseShareDialog = React.useCallback(() => {
        setIsShareDialogOpen(false);
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    p: 2,
                    bgcolor: "primary.main",
                    justifyContent: "space-between",
                    borderRadius: 3,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "stretch", gap: 1.5 }}>
                    <IconButton onClick={handleGoBack}>
                        <ArrowLeft2 size={24} color={theme.palette.neutrals[70]} />
                    </IconButton>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            flexGrow: 1,
                            mt: 0.5,
                            pr: !isHovered && !isEditing ? 7 : undefined,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <>
                                {isEditing ? (
                                    <TextField
                                        value={editedName}
                                        onChange={handleChangeChatName}
                                        onBlur={handleEditComplete}
                                        onKeyDown={handleKeyDown}
                                        variant="standard"
                                        autoFocus
                                        InputProps={{ disableUnderline: true, sx: { height: "20px" } }}
                                        sx={{
                                            height: "20px",
                                            "& .MuiInput-root": {
                                                color: "neutrals.80",
                                                fontSize: "subtitle1.fontSize",
                                                fontWeight: 500,
                                            },
                                        }}
                                    />
                                ) : chatName != null ? (
                                    <Typography
                                        variant="subtitle1"
                                        noWrap
                                        color="neutrals.80"
                                        fontWeight={500}
                                        sx={{ lineHeight: 1.25 }}
                                    >
                                        {chatName}
                                    </Typography>
                                ) : (
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        sx={{ fontSize: "subtitle1.fontSize", lineHeight: 1.25 }}
                                    />
                                )}
                                {(isHovered || isEditing) && (
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={handleEditClick}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{ p: 0.5, maxHeight: theme.spacing(2) }}
                                        >
                                            <Edit2 size={16} color={theme.palette.neutrals[50]} />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={handleShare}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{ p: 0.5, maxHeight: theme.spacing(2) }}
                                        >
                                            <IosShare sx={{ fontSize: 16, color: theme.palette.neutrals[50] }} />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        </Box>
                        <Typography variant="caption" color="neutrals.50">
                            From search results
                        </Typography>
                    </Box>
                </Box>
                <Avatar
                    sx={{
                        bgcolor: "surface.75",
                        color: "secondary.main",
                        fontSize: "1rem",
                        border: 1,
                        borderColor: "neutrals.30",
                    }}
                >
                    {initials ?? ""}
                </Avatar>
            </Box>
            <ShareDialog open={isShareDialogOpen} onClose={handleCloseShareDialog} chatId={chatId} />
        </>
    );
};
